.jselect-button {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    white-space: nowrap;
}

.jselect {
    height: min-content;
    font-weight: 700;
    font-size: 20px;
    padding: 5px 15px;
    border-radius: 18px;
    transition: background-color 0.2s ease, max-height 0.5s ease, box-shadow 0.2s ease;
    cursor: pointer;
    overflow: hidden;
    user-select: none;
    position: absolute;
    border: none;
    appearance: none;
    
    box-shadow: 0 0 0 0px var(--background);
}
.jselect:focus {
    outline: none !important;
    box-shadow: 0 0 0 5px var(--background);
}

.jcheckbox {
    width: 14px;
    height: 14px;
    display: flex;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.2s ease, box-shadow 0.2s ease, border-color 0.2s ease;
    border: solid 6px;
    box-shadow: 0 0 0 0px var(--background);
}

.jcheckbox:focus {
    outline: none !important;
    box-shadow: 0 0 0 5px var(--background);
}

.jselect-arrow-mobile {
    height: 25px;
    position: relative;
    left: 234px;
    bottom: 38px;
    transition: transform 500ms ease;
    pointer-events: none;
}

.jselect-arrow-desktop {
    height: 15px;
    display: flex;
    justify-self: left;
    justify-content: right;
    margin-left: auto;
}

.jselect.mobile {
    display: none;
}
.jselect.desktop {
    display: block;
    z-index: 1;
}

/* Looks really weird on IOS*/
@media screen and (max-width: 600px) {
    .jselect {
        position: relative;
        width: 275px;
        height: 50px;
        border-radius: 50px;
        font-size: 25px;
    }
    .jselect.mobile {
        display: block;
    }
    .jselect.desktop {
        display: none;
    }
    .jcheckbox {
        width: 20px;
        height: 20px;
        border-radius: 8px;
        border: solid 8px;
    }
}