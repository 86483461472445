.scaleinfochord-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    font-weight: 700;
    width: 100%;
    border-radius: 20px;
    background-color: var(--background2);
    max-width: 500px;
    padding: 10px 20px;
    user-select: none;
}

.scaleinfochord-container > hr {
    background-color: var(--background);
    height: 4px;
    width: 100%;
    margin: 1px;
    border: 1px solid var(--background);
    box-shadow: none;
    border-radius: 10px;
}

.scaleinfochord-chord {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    width: 100%;
    overflow: auto;
    font-size: 26px;
}

.scaleinfochord-note, .scaleinfochord-desc-note {
    flex-shrink: 0;
    padding: 5px;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--accent2);
    border-radius: 5%;
    margin-left: 10px;
}


.scaleinfochord-note.seventh.included, .scaleinfochord-desc-note.seventh {
    color: var(--accent1);
}
.scaleinfochord-note.seventh.unincluded {
    color: var(--background);
}
.scaleinfochord-note.highlighted {
    background: var(--background3);
    box-shadow: inset 0px 0px 0px 3px var(--background);
}
.scaleinfochord-note:not(.unincluded) {
    cursor: pointer;
}
.scaleinfochord-note:not(.unincluded) > span {
    transition: scale 0.2s ease;
    scale: 100%;
}
.scaleinfochord-note:not(.unincluded):hover > span {
    scale: 120%;
}


.scaleinfochord-numeral, .scaleinfochord-desc-numeral {
    flex-shrink: 0;
    width: 50px;
    padding-right: 10px;
}
.scaleinfochord-numeral {
    cursor: pointer;
}

.scaleinfochord-label, .scaleinfochord-desc-label {
    width: 200px;
    min-width: 60px;
    padding: 8px;
    border-radius: 5px;
}
.scaleinfochord-label {
    cursor: pointer;
}

.scaleinfochord-label.highlighted {
    background: var(--background3);
    box-shadow: inset 0px 0px 0px 3px var(--background);
}

.scaleinfochord-label > div, 
.scaleinfochord-numeral > div {
    max-width: fit-content;
    min-width: inherit;
    scale: 100%;
    transition: scale 0.2s ease;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: '-';
}

.scaleinfochord-label > div > span, 
.scaleinfochord-numeral > div > span {
    display: inline;
}

.scaleinfochord-chordinfo {
    display: contents;
}

/* Hover effects for all elements when either one is hovered */
.scaleinfochord-chordinfo:hover > .scaleinfochord-label > div,
.scaleinfochord-chordinfo:hover > .scaleinfochord-numeral > div {
    scale: 110%;
}

/* To prevent overflow from looking weird on really small screens*/
@media screen and (max-width: 400px) {
    .scaleinfochord-label, .scaleinfochord-desc-label {
        display: none;
    }
    .scaleinfochord {
        justify-content: center;
    }
    .scaleinfochord-note, 
    .scaleinfochord-desc-note,
    .scaleinfochord-numeral,
    .scaleinfochord-desc-numeral {
        flex-shrink: 1;
    }
}

.scale-info__chord__container {
    gap: 5px;
}