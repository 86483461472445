.scaleinfoguitar-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 22px;
    font-weight: 700;
    width: 95%;
    max-width: fit-content;
    border-radius: 20px;
    background-color: var(--background2);
    padding: 20px 20px;
}

.scaleinfoguitar-fretboard {
    scrollbar-color: var(--text) var(--background2);
    overflow-x: auto;
    overflow-y: hidden;
    width: 100%;
    user-select: none;
    height: auto;
    padding-bottom: 10px;
    padding-right: 10px;
    padding-left: 10px;
}

.scaleinfoguitar-fretboard > .string {
    display: flex;
    transform: translate(10px, 24px);
}

.notecontainer > .settings {
    transform:translate(-10px,-20px);
    display: flex;
    justify-content: center;
    height: 40px;
    align-items: center;
    cursor: pointer;

}

.notecontainer > .settings > svg {
    height: 15px;
    scale: 100%;
    transition: scale 0.2s ease;
}
.notecontainer > .settings:hover > svg {
    scale: 120%;
}

.scaleinfoguitar-fretboard > .string:first-child > .notecontainer {
    border-top: solid 4px var(--text);
}   /*  All notecontainers on the top string  */

.scaleinfoguitar-fretboard > .string:nth-last-child(3) > .notecontainer {
    border-bottom-width: 4px;
}

.scaleinfoguitar-fretboard > .string:nth-last-child(2) > .notecontainer {
    border-color: transparent;
}   /*  All notecontainers on the bottom string  */


.scaleinfoguitar-fretboard > .string:last-child > .notecontainer {
    border-color: transparent;
    transform: translateY(-20px);
    display: flex;
    align-items: center;
    justify-content: center;
}


.scaleinfoguitar-fretboard > .string:nth-last-child(2) > .notecontainer > .note {
    transform: translateY(-21.5px);
}
.scaleinfoguitar-fretboard > .string:nth-last-child(2) > .notecontainer > .settings {
    transform: translate(-10px,-21.5px);
}

.scaleinfoguitar-fretboard > .string:first-child > .notecontainer:first-child {
    border-top: solid 5px var(--background2);
}   /*  Top-left notecontainer holding the open notes  */

.string:last-child > .notecontainer:first-child {
    border-top: solid 2px var(--background2);
}   /*  Bottom-left notecontainer holding the open notes*/


.notecontainer {
    width: 70px;
    display: flex;
    justify-content: center;
    border: solid 2px var(--text);
}

.notecontainer:first-child {
    border-left: solid 4px var(--text);
    border: solid 2px var(--background2);
}   /*  First vertical row (open notes)  */

.notecontainer:nth-child(2){
    border-left: solid 4px var(--text);
}   /*  Second vertical row (first on the actual fretboard)  */

.notecontainer:last-child {
    border-right: solid 4px var(--text);
}   /*  Last vertical row  */


.notecontainer > .note {
    transform: translateY(-20px);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    width: 40px;
    height: 40px;
    border: solid 3px var(--text);
    background-color: var(--background3);
    color: var(--text);
    transition: scale 0.2s ease;
    cursor: pointer;
    scale: 100%;
    z-index: 0;
}

.notecontainer > .number {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.notecontainer > .note:hover {
    scale: 120%;
    z-index: 1;
}

.notecontainer > .note.tonic {
    color: var(--background3);
    background-color: var(--accent2hover);
    border-color: var(--accent2);
}

.notecontainer > .note.significant {
    /* color: var(--background3);
    background-color: var(--accent1hover);
    border-color: var(--accent1); */
    
    background-color: var(--text);
    color: var(--background3);

}

.notecontainer > .note-open {
    transform: translateY(-20px);
    display: flex;
    justify-content: center;
    width: 40px;
    height: 40px;
    align-items: center;
}

